<template>
    <div></div>
</template>

<script>
    import getContractApi from '../api/contract'
    export default {
        name: "MembershipNFTDatas",
        created() {
            this.market = getContractApi().getMembershipMarket();
            this.market.fetchTotalSupply().then(count => {
                // console.dir(count.toNumber())
                // for(let i = 0; i <= count.toNumber(); i++) {
                for(let i = 351; i <= count.toNumber(); i++) {
                    this.market.fetchItemInfoByTokenId(i).then(data => {
                        // console.dir(data)
                        this.$store.dispatch('phpNFT/setMembershipNFT', data).then((returnData) => {
                            console.dir(returnData.data)
                        }).catch(() => {
                            alert('멤버쉽 등록중 오류가 발생하였습니다.')
                        })
                    })
                }

            })
        }
    }
</script>

<style scoped>

</style>